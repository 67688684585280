import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faHouse,
  faArrowLeft,
  faArrowRight,
  faXmark,
  faPlus,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  requestGetClasses,
  requestAddPayment,
  requestGetPayment,
} from "../utils/resource";
import { useNavigate } from "react-router-dom";
import "../styles/PaymentControl.css";

const PaymentControl = () => {
  const navigate = useNavigate();
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMonthPayment, setSelectedMonthPayment] = useState(
    new Date().toISOString().slice(0, 7)
  );
  const [selectedMonthUnpayment, setSelectedMonthUnpayment] = useState(
    new Date().toISOString().slice(0, 7)
  );
  const [currentPagePayment, setCurrentPagePayment] = useState(1);
  const [currentPageUnpayment, setCurrentPageUnpayment] = useState(1);
  const [isAdding, setIsAdding] = useState(false);
  const [myClasses, setMyClasses] = useState([]);
  const [studentClassesCount, setStudentClassesCount] = useState({});
  const [originalStudents, setOriginalStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const [studentsWithPendingPayments, setStudentsWithPendingPayments] =
    useState([]);
  const [paidStudents, setPaidStudents] = useState([]);
  const [currentDay, setCurrentDay] = useState(new Date().getDate());

  const [defaultValues, setDefaultValues] = useState([140, 210, 300]);
  const [showRevenue, setShowRevenue] = useState(false);

  const countClasses = () => {
    return myClasses.reduce((count, dayEntry) => {
      Object.values(dayEntry.time).forEach((students) => {
        students.forEach((student) => {
          count[student.name] = (count[student.name] || 0) + 1;
        });
      });
      return count;
    }, {});
  };

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (isAdding) {
      requestGetPayment(selectedMonthPayment, setPaymentData);
      setSearchTerm("");
      setSelectedMonthPayment(new Date().toISOString().slice(0, 7));
      setCurrentPagePayment(1);
    } else {
      requestGetPayment(selectedMonthUnpayment, setPaymentData);
    }
  }, [isAdding]);

  useEffect(() => {
    if (myClasses.length > 0) {
      setStudentClassesCount(countClasses());
    }
  }, [myClasses]);

  useEffect(() => {
    requestGetPayment(selectedMonthPayment, setPaymentData);
  }, [selectedMonthPayment]);

  useEffect(() => {
    requestGetPayment(selectedMonthUnpayment, setPaymentData);
  }, [selectedMonthUnpayment]);

  useEffect(() => {
    if (Object.keys(paymentData).length > 0) {
      const studentsArray = paymentData.paymentData
        .filter((student) => studentClassesCount[student.name])
        .map((student, index) => {
          const classesFromStudentCount =
            studentClassesCount[student.name] || student.classes;
          return {
            id: index,
            name: student.name,
            classes: Math.max(student.classes, classesFromStudentCount),
            paid: student.paid,
            paymentDay: student.paymentDay,
          };
        });

      const newStudents = Object.keys(studentClassesCount)
        .filter(
          (name) =>
            !paymentData.paymentData.some((student) => student.name === name)
        )
        .map((name, index) => ({
          id: paymentData.paymentData.length + index,
          name,
          classes: studentClassesCount[name],
          paid: false,
          paymentDay: "",
        }));

      const allStudents = [...studentsArray, ...newStudents];

      const initialSelectedStudents = {};
      allStudents.forEach((student) => {
        initialSelectedStudents[student.id] = student.paid;
      });

      setFilteredStudents(allStudents);
      setOriginalStudents(allStudents);
      setSelectedStudents(initialSelectedStudents);

      const unpaidStudents = allStudents.filter((student) => !student.paid);
      const paidStudents = allStudents.filter((student) => student.paid);

      setPaidStudents(paidStudents);
      setStudentsWithPendingPayments(unpaidStudents);
    } else {
      if (Object.keys(studentClassesCount).length > 0) {
        const studentsArray = Object.keys(studentClassesCount).map(
          (name, index) => ({
            id: index,
            name,
            classes: studentClassesCount[name],
            paid: false,
            paymentDay: "",
          })
        );

        const initialSelectedStudents = {};
        studentsArray.forEach((student) => {
          initialSelectedStudents[student.id] = student.paid;
        });

        setFilteredStudents(studentsArray);
        setOriginalStudents(studentsArray);
        setStudentsWithPendingPayments(studentsArray);
        setPaidStudents([]);
        setSelectedStudents(initialSelectedStudents);
      }
    }
  }, [paymentData, studentClassesCount]);

  useEffect(() => {
    requestGetClasses(localStorage.getItem("_id"), setMyClasses);
  }, []);

  useEffect(() => {
    const results = searchTerm
      ? originalStudents.filter((student) =>
          student.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : originalStudents;

    setFilteredStudents(results);
    setCurrentPagePayment(1);
  }, [searchTerm, originalStudents]);

  const handlePaymentCheck = (id) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleDayChange = (id, day) => {
    setFilteredStudents((prev) =>
      prev.map((student) =>
        student.id === id ? { ...student, paymentDay: day } : student
      )
    );
  };

  const generateDays = () => Array.from({ length: 31 }, (_, i) => i + 1);

  const handleCancel = () => navigate("/dashboard");

  const handlePageChangePayment = (pageNumber) =>
    setCurrentPagePayment(pageNumber);

  const handlePageChangeUnpayment = (pageNumber) =>
    setCurrentPageUnpayment(pageNumber);

  const savePayment = () => {
    const paymentData = originalStudents.map((student) => ({
      name: student.name,
      classes: student.classes,
      paid: selectedStudents[student.id] || false,
      paymentDay:
        filteredStudents.find((s) => s.id === student.id)?.paymentDay || "",
    }));
    requestAddPayment(
      selectedMonthPayment,
      paymentData,
      localStorage.getItem("_id")
    );
    setIsAdding(false);
  };

  const itemsPerPage = 5;
  const currentStudents = filteredStudents.slice(
    (currentPagePayment - 1) * itemsPerPage,
    currentPagePayment * itemsPerPage
  );
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  const unpaidItemsPerPage = 5;
  const currentUnpaidStudents = studentsWithPendingPayments.slice(
    (currentPageUnpayment - 1) * unpaidItemsPerPage,
    currentPageUnpayment * unpaidItemsPerPage
  );
  const totalUnpaidPages = Math.ceil(
    studentsWithPendingPayments.length / unpaidItemsPerPage
  );

  const calculateRevenue = () => {
    if (paidStudents.length > 0) {
      const revenue = paidStudents.reduce((total, student) => {
        return total + defaultValues[student.classes - 1];
      }, 0);
      return revenue;
    }
    return 0;
  };

  return (
    <div className="payment-container">
      <main className="payment-main">
        <div className="button-bar">
          {isAdding ? (
            <>
              <FontAwesomeIcon
                icon={faSave}
                className="icon save-icon"
                onClick={savePayment}
                title="Salvar"
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="icon cancel-icon"
                onClick={() => setIsAdding(false)}
                title="Cancelar"
              />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className="icon add-icon"
                onClick={() => setIsAdding(true)}
                title="Adicionar Estudante"
              />
              <FontAwesomeIcon
                icon={faHouse}
                className="icon cancel-icon"
                onClick={handleCancel}
                title="Cancelar"
              />
            </>
          )}
        </div>

        {!isAdding && (
          <div>
            <div className="faturamento-container">
              <p className="faturamento">
                Faturamento do mês: R${" "}
                {showRevenue ? calculateRevenue().toFixed(2) : "***"}
                <FontAwesomeIcon
                  icon={showRevenue ? faEye : faEyeSlash}
                  onClick={() => setShowRevenue(!showRevenue)}
                  title={
                    showRevenue ? "Ocultar faturamento" : "Mostrar faturamento"
                  }
                  className="icon revenue-icon"
                />
              </p>
            </div>

            <div className="classes-values">
              <p className="values-title">Valores das aulas:</p>
              <div className="value-inputs">
                {defaultValues.map((value, index) => (
                  <div key={index}>
                    <label>
                      {index + 1} {index === 0 ? "Aula" : "Aulas"}
                    </label>
                    <input
                      type="number"
                      value={value}
                      onChange={(e) => {
                        const newValues = [...defaultValues];
                        newValues[index] = parseFloat(e.target.value) || 0;
                        setDefaultValues(newValues);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <p className="clients-title">
              Alunos que faltam pagar: {studentsWithPendingPayments.length}
            </p>

            <div className="date-selector" style={{ marginTop: "20px" }}>
              <input
                type="month"
                id="month-select-unpayment"
                value={selectedMonthUnpayment}
                onChange={(e) => setSelectedMonthUnpayment(e.target.value)}
              />
            </div>

            <table className="unpayment-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Aulas</th>
                </tr>
              </thead>
              <tbody>
                {currentUnpaidStudents.length > 0 ? (
                  currentUnpaidStudents.map((student) => (
                    <tr key={student.id}>
                      <td>{student.name}</td>
                      <td>{student.classes}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">Nenhum estudante encontrado.</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="pagination">
              <button
                onClick={() =>
                  setCurrentPageUnpayment((prev) => Math.max(prev - 1, 1))
                }
                disabled={currentPageUnpayment === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              {Array.from({ length: totalUnpaidPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChangeUnpayment(index + 1)}
                  className={index + 1 === currentPageUnpayment ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() =>
                  setCurrentPageUnpayment((prev) =>
                    Math.min(prev + 1, totalUnpaidPages)
                  )
                }
                disabled={currentPageUnpayment === totalUnpaidPages}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}

        {isAdding && (
          <div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar por nome..."
            />

            <div className="date-selector" style={{ marginTop: "20px" }}>
              <input
                type="month"
                id="month-select-payment"
                value={selectedMonthPayment}
                onChange={(e) => setSelectedMonthPayment(e.target.value)}
              />
            </div>

            <table className="payment-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Aulas</th>
                  <th>Pago?</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.length > 0 ? (
                  currentStudents.map((student) => (
                    <tr key={student.id}>
                      <td>{student.name}</td>
                      <td>{student.classes}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedStudents[student.id] || false}
                          onChange={() => handlePaymentCheck(student.id)}
                        />
                      </td>
                      <td>
                        <select
                          value={
                            filteredStudents.find((s) => s.id === student.id)
                              ?.paymentDay || currentDay
                          }
                          onChange={(e) =>
                            handleDayChange(student.id, e.target.value)
                          }
                        >
                          <option value="">Selecionar Dia</option>
                          {generateDays().map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">Nenhum estudante encontrado.</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="pagination">
              <button
                onClick={() =>
                  setCurrentPagePayment((prev) => Math.max(prev - 1, 1))
                }
                disabled={currentPagePayment === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChangePayment(index + 1)}
                  className={index + 1 === currentPagePayment ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() =>
                  setCurrentPagePayment((prev) =>
                    Math.min(prev + 1, totalPages)
                  )
                }
                disabled={currentPagePayment === totalPages}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default PaymentControl;
