import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { requestAddInstrutors } from "../utils/resource";

const Signup = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName.trim() && password.trim() && email.trim() && phone.trim()) {
      requestAddInstrutors(email, userName, password, phone, navigate);
      setPassword("");
      setUserName("");
      setEmail("");
      setPhone("");
    }
  };

  return (
    <main className="signup">
      <form className="signup__form" onSubmit={handleSubmit}>
        <h2 className="signup__title">Criar conta para Instrutor</h2>
        <label htmlFor="username">Nome do Instrutor</label>
        <input
          id="username"
          name="username"
          required
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <label htmlFor="phone">Telefone</label>
        <input
          id="phone"
          type="phone"
          name="phone"
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Senha para acessar essa plataforma</label>
        <input
          id="password"
          type="password"
          name="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="signupButton">Registrar</button>
        <p style={{ textAlign: "center", marginTop: "30px" }}>
          Já tem uma conta?{" "}
          <Link className="link" to="/">
            Entrar
          </Link>
        </p>
      </form>
    </main>
  );
};

export default Signup;
