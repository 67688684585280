import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faUsers,
  faList,
  faClock,
  faDollarSign,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import "../styles/Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  const cardData = [
    { title: "Pagamentos", path: "/payment-control", icon: faDollarSign },
    {
      title: "Frequência",
      path: "/attendance-list",
      icon: faList,
    },
    {
      title: "Aulas",
      path: "/classes",
      icon: faCalendarDays,
    },
    { title: "Alunos", path: "/students", icon: faUsers },
    {
      title: "Horários",
      path: "/my-schedules",
      icon: faClock,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("_id");
    navigate("/");
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1></h1>
        <FontAwesomeIcon
          icon={faArrowRightFromBracket}
          className="icon logout-icon"
          onClick={handleLogout}
        />
      </header>
      <div className="card-grid">
        {cardData.map((card, index) => (
          <div className="card" key={index}>
            <Link to={card.path} className="card-link">
              <div className="card-content">
                <FontAwesomeIcon icon={card.icon} className="card-icon" />
                <h2>{card.title}</h2>
              </div>
              <div className="card-footer">
                <hr className="card-line" />
                <span className="card-text">Acessar</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dashboard;
