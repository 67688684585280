import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestLoginInstructor } from "../utils/resource";
import logo from "../imgs/logo-jessica-2.svg";
import "../styles/Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() && password.trim()) {
      requestLoginInstructor(email, password, navigate);
      setEmail("");
      setPassword("");
    }
  };

  return (
    <main className="login-container">
      <img src={logo} alt="Logo" className="logo" />
      <p className="admin-access">Acesso somente Administrador</p>
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <input
        id="email"
        name="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="input-field"
        required
      />
      <label htmlFor="password" className="form-label">
        Senha
      </label>
      <input
        id="password"
        type={showPassword ? "text" : "password"} // Alterna entre text e password
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-field"
        required
      />
      <label>
        <input
          type="checkbox"
          checked={showPassword}
          onChange={() => setShowPassword(!showPassword)} // Alterna o estado
        />
        Mostrar Senha
      </label>
      <button className="loginButton" onClick={handleSubmit}>
        Entrar
      </button>
    </main>
  );
};

export default Login;
