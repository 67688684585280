import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { requestAddSchedules, requestGetSchedules } from "../utils/resource";
import moment from "moment";
import "../styles/Schedules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faHouse } from "@fortawesome/free-solid-svg-icons";

const Schedules = () => {
  const navigate = useNavigate();

  const [schedules, setSchedules] = useState([
    { day: "Seg", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Ter", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Qua", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Qui", classes: 1, times: [{ startTime: "", endTime: "" }] },
    { day: "Sex", classes: 1, times: [{ startTime: "", endTime: "" }] },
  ]);

  const [hasChanges, setHasChanges] = useState(false);

  const sortSchedules = (schedulesToSort) => {
    return schedulesToSort.map((schedule) => ({
      ...schedule,
      times: schedule.times.sort((a, b) => {
        if (a.startTime && b.startTime) {
          const timeA = moment(a.startTime, "HH:mm");
          const timeB = moment(b.startTime, "HH:mm");
          return timeA - timeB;
        }
        return 0;
      }),
    }));
  };

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    } else {
      requestGetSchedules(localStorage.getItem("_id"), (fetchedSchedules) => {
        const sortedSchedules = sortSchedules(fetchedSchedules);
        setSchedules(sortedSchedules);
      });
    }
  }, [navigate]);

  const handleClassesChange = (e, dayIndex) => {
    const value = parseInt(e.target.value);
    const updatedSchedule = [...schedules];

    const existingTimes = updatedSchedule[dayIndex].times || [];

    const newTimes = Array.from(
      { length: value - existingTimes.length },
      () => ({
        startTime: "",
        endTime: "",
      })
    );

    updatedSchedule[dayIndex] = {
      ...updatedSchedule[dayIndex],
      classes: value,
      times: [...existingTimes, ...newTimes].slice(0, value),
    };

    setSchedules(updatedSchedule);
    setHasChanges(true);
  };

  const handleTimeChange = (date, dayIndex, timeIndex, isStart) => {
    const updatedSchedule = [...schedules];
    const timeField = isStart ? "startTime" : "endTime";
    updatedSchedule[dayIndex].times[timeIndex][timeField] = date
      ? moment(date).format("HH:mm")
      : "";
    setSchedules(updatedSchedule);
    setHasChanges(true);
  };

  const handleSaveSchedules = () => {
    requestAddSchedules(localStorage.getItem("_id"), schedules);
    const sortedSchedules = sortSchedules(schedules);
    setSchedules(sortedSchedules);
    setHasChanges(false);
  };

  const handleCancel = () => {
    if (hasChanges) {
      const confirmLeave = window.confirm(
        "Você tem alterações não salvas. Deseja salvar antes de sair?"
      );
      if (confirmLeave) {
        handleSaveSchedules();
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="schedules-container">
      <main className="schedules-main">
        <div className="button-bar">
          <FontAwesomeIcon
            icon={faSave}
            className="icon save-icon"
            onClick={handleSaveSchedules}
            title="Salvar"
            style={{
              cursor: hasChanges ? "pointer" : "not-allowed",
              opacity: hasChanges ? 1 : 0.5,
            }}
            disabled={!hasChanges}
          />
          <FontAwesomeIcon
            icon={faHouse}
            className="icon cancel-icon"
            onClick={handleCancel}
            title="Cancelar"
          />
        </div>
        <div className="schedules-form">
          {schedules.map((sch, dayIndex) => (
            <div className="schedule-day" key={dayIndex}>
              <p style={{ color: "#fff" }} className="day-label">
                {sch.day}
              </p>

              <div className="select-wrapper">
                <label htmlFor="classes" style={{ color: "#fff" }}>
                  Número de Aulas
                </label>
                <select
                  name="classes"
                  value={sch.classes}
                  onChange={(e) => handleClassesChange(e, dayIndex)}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>

              {sch.times.map((timeSlot, timeIndex) => (
                <div className="time-slot" key={timeIndex}>
                  <div className="time-slot-header">
                    <p style={{ color: "#fff" }}>Aula {timeIndex + 1}</p>
                  </div>
                  <div className="time-selectors">
                    <div className="select-wrapper">
                      <label
                        htmlFor={`startTime-${dayIndex}-${timeIndex}`}
                        style={{ color: "#fff" }}
                      >
                        Início
                      </label>
                      <DatePicker
                        selected={
                          timeSlot.startTime
                            ? moment(timeSlot.startTime, "HH:mm").toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleTimeChange(date, dayIndex, timeIndex, true)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeCaption="Hora"
                        className="time-selector"
                        timeFormat="HH:mm"
                      />
                    </div>
                    <div className="select-wrapper">
                      <label
                        htmlFor={`endTime-${dayIndex}-${timeIndex}`}
                        style={{ color: "#fff" }}
                      >
                        Fim
                      </label>
                      <DatePicker
                        selected={
                          timeSlot.endTime
                            ? moment(timeSlot.endTime, "HH:mm").toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleTimeChange(date, dayIndex, timeIndex, false)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeCaption="Hora"
                        className="time-selector"
                        timeFormat="HH:mm"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Schedules;
