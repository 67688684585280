import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Dashboard";
import Schedules from "./components/Schedules";
import Students from "./components/Students";
import Classes from "./components/Classes";
import AttendanceList from "./components/AttendanceList";
import BioLinks from "./components/BioLinks";
import PaymentControl from "./components/PaymentControl";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BioLinks />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-schedules" element={<Schedules />} />
          <Route path="/students" element={<Students />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/attendance-list" element={<AttendanceList />} />
          <Route path="/payment-control" element={<PaymentControl />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
