import React, { useState, useEffect } from "react";
import {
  requestGetClasses,
  requestAddAttendanceList,
  requestGetAttendanceList,
  requestGetAbsenceList,
} from "../utils/resource";
import "../styles/AttendanceList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faHouse,
  faArrowLeft,
  faArrowRight,
  faXmark,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { format, parse, addDays, subDays, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

const formatDate = (date) => {
  return isValid(date) ? format(date, "dd-MM-yyyy", { locale: ptBR }) : "";
};

const parseDate = (dateString) => {
  const parsedDate = parse(dateString, "yyyy-MM-dd", new Date(), {
    locale: ptBR,
  });
  return isValid(parsedDate) ? parsedDate : new Date();
};

const AttendanceList = () => {
  const navigate = useNavigate();

  const [attendance, setAttendance] = useState({});
  const [absenceList, setAbsenceList] = useState([]);

  const [myClasses, setMyClasses] = useState([]);
  const [date, setDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(
    daysOfWeek[new Date().getDay()]
  );
  const [isAdding, setIsAdding] = useState(false);
  const [studentClassesCount, setStudentClassesCount] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 5;

  const countClasses = () => {
    const count = {};
    myClasses.forEach((dayEntry) => {
      Object.values(dayEntry.time).forEach((students) => {
        students.forEach((student) => {
          if (count[student.name]) {
            count[student.name]++;
          } else {
            count[student.name] = 1;
          }
        });
      });
    });
    return count;
  };

  function getStudentsByDay(dia) {
    const result = myClasses.find((item) => item.day === dia);
    if (result && result.time) {
      return Object.values(result.time).flat();
    }
    return [];
  }

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (Object.keys(studentClassesCount).length > 0) {
      requestGetAbsenceList(localStorage.getItem("_id"))
        .then((result) => {
          const filteredResult = result.filter((student) =>
            studentClassesCount.hasOwnProperty(student.name)
          );
          setAbsenceList(filteredResult);
        })
        .catch((error) => {
          console.error("Error fetching absence list:", error);
        });
    }
  }, [studentClassesCount]);

  useEffect(() => {
    requestGetClasses(localStorage.getItem("_id"), setMyClasses);
  }, []);

  useEffect(() => {
    const total = Math.ceil(
      absenceList.filter((item) => item.name.toLowerCase().includes(searchTerm))
        .length / itemsPerPage
    );
    setTotalPages(total);
  }, [absenceList, searchTerm]);

  useEffect(() => {
    if (isAdding === true) {
      requestGetAttendanceList(
        localStorage.getItem("_id"),
        formatDate(date),
        setAttendance
      );
    } else {
      setDate(new Date());
      setSelectedDay(daysOfWeek[new Date().getDay()]);
    }
  }, [isAdding]);

  useEffect(() => {
    if (myClasses.length > 0) {
      setStudentClassesCount(countClasses());
    }
  }, [myClasses]);

  useEffect(() => {
    requestGetAttendanceList(
      localStorage.getItem("_id"),
      formatDate(date),
      setAttendance
    );
    const dayOfWeek = daysOfWeek[date.getDay()];
    setSelectedDay(dayOfWeek);
  }, [date]);

  const handleAttendanceChange = (studentName) => {
    setAttendance((prevState) => ({
      ...prevState,
      [studentName]: !prevState[studentName],
    }));
  };

  const handleDateChange = (event) => {
    const newDate = parseDate(event.target.value);
    setDate(newDate);
  };

  const saveAttendance = async () => {
    const formattedDate = formatDate(date);
    const students = getStudentsByDay(selectedDay);
    students.forEach((student) => {
      if (!(student.name in attendance)) {
        attendance[student.name] = false;
      }
    });

    await requestAddAttendanceList(
      localStorage.getItem("_id"),
      formattedDate,
      attendance
    );
    await requestGetAbsenceList(localStorage.getItem("_id"), setAbsenceList);
    setIsAdding(false);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handlePreviousDay = () => {
    setDate(subDays(date, 1));
  };

  const handleNextDay = () => {
    setDate(addDays(date, 1));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredStudents = absenceList
    .filter((item) => item.name.toLowerCase().includes(searchTerm))
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="attendance-container">
      <main className="attendance-main">
        <div className="button-bar">
          {isAdding ? (
            <>
              <FontAwesomeIcon
                icon={faSave}
                className="icon save-icon"
                onClick={saveAttendance}
                title="Salvar"
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="icon cancel-icon"
                onClick={() => setIsAdding(false)}
                title="Cancelar"
              />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className="icon add-icon"
                onClick={() => setIsAdding(true)}
                title="Adicionar Estudante"
              />
              <FontAwesomeIcon
                icon={faHouse}
                className="icon cancel-icon"
                onClick={() => handleCancel()}
                title="Cancelar"
              />
            </>
          )}
        </div>

        {!isAdding && (
          <div className="">
            <input
              type="text"
              placeholder="Buscar por nome"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <table className="student-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Aulas</th>
                  <th>Faltas</th>
                  <th>Datas</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => (
                  <tr key={student.name}>
                    <td>{student.name}</td>
                    <td>{studentClassesCount[student.name] || 0}</td>
                    <td>{student.absences}</td>
                    <td>
                      {student.timestamps.map((timestamp, index) => (
                        <div key={index}>
                          {format(new Date(timestamp * 1000), "dd-MM-yyyy")}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={index + 1 === currentPage ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}

        {isAdding && (
          <div>
            <div className="date-selector">
              <button onClick={handlePreviousDay}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <label>
                Frequência:
                <input
                  type="date"
                  value={format(date, "yyyy-MM-dd")}
                  onChange={handleDateChange}
                />
              </label>
              <button onClick={handleNextDay}>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>

            <table className="student-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Presente?</th>
                </tr>
              </thead>
              <tbody>
                {getStudentsByDay(selectedDay).map((student) => (
                  <tr key={student.name}>
                    <td>{student.name}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={attendance[student.name] || false}
                        onChange={() => handleAttendanceChange(student.name)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </main>
    </div>
  );
};

export default AttendanceList;
