import { initializeApp } from "firebase/app";
import {
  addDoc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  getFirestore,
  collection,
  doc,
  setDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { parse, subDays } from "date-fns";

const firebaseConfig = {
  apiKey: "AIzaSyBuaJwEif975gUZ1QXTLbmW9Xq8M1N8M-E",
  authDomain: "studio-pilates-zanardi.firebaseapp.com",
  databaseURL: "https://studio-pilates-zanardi-default-rtdb.firebaseio.com",
  projectId: "studio-pilates-zanardi",
  storageBucket: "studio-pilates-zanardi.appspot.com",
  messagingSenderId: "157230453177",
  appId: "1:157230453177:web:5b6de5e77b35e05be5a668",
  measurementId: "G-EPFEKZMEBB",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

const instructorsCollection = collection(db, "instructors");
const schedulesCollection = collection(db, "schedules");
const studentsCollection = collection(db, "students");
const classesCollection = collection(db, "classes");
const paymentCollection = collection(db, "payment");
const attendanceCollection = collection(db, "attendance");

function generateBase64(email, password) {
  const combined = `${email}:${password}`;
  const encoded = btoa(combined);
  return encoded;
}

export async function requestLoginInstructor(email, password, navigate) {
  try {
    const docRef = doc(instructorsCollection, email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const passwordBase64 = generateBase64(email, password);

      if (userData.password === passwordBase64) {
        localStorage.setItem("_id", email);
        navigate("/dashboard");
      } else {
        return {
          status: "error",
          message: "Invalid credentials",
        };
      }
    } else {
      return {
        status: "error",
        message: "User not found",
      };
    }
  } catch (err) {
    return {
      status: "error",
      message: "Login error",
      error: err,
    };
  }
}

export async function requestAddInstrutors(
  email,
  userName,
  password,
  phone,
  navigate
) {
  try {
    const passwordBase64 = generateBase64(email, password);
    const docRef = doc(instructorsCollection, email);

    await setDoc(docRef, {
      userName: userName,
      id: email,
      password: passwordBase64,
      phone: phone,
    });

    navigate("/");
    return { status: "success", message: "Document created successfully" };
  } catch (err) {
    return {
      status: "error",
      message: "Account creation failed",
      error: err,
    };
  }
}

export async function requestAddSchedules(email, schedules) {
  try {
    const docRef = doc(schedulesCollection, email);
    await setDoc(docRef, {
      id: email,
      schedules: schedules,
    });
    return { status: "success", message: "Document created successfully" };
  } catch (err) {
    return {
      status: "error",
      message: "Add schedules failed",
      error: err,
    };
  }
}

export async function requestGetAbsenceList(email) {
  try {
    const endDate = new Date();
    const startDate = subDays(endDate, 60);

    const startTimestamp = Timestamp.fromDate(startDate).seconds;
    const endTimestamp = Timestamp.fromDate(endDate).seconds;

    const q = query(
      attendanceCollection,
      where("id", "==", email),
      where("timestamp", ">=", startTimestamp),
      where("timestamp", "<=", endTimestamp)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("Nenhum documento encontrado.");
      return [];
    }

    const attendanceData = querySnapshot.docs.reduce((acc, doc) => {
      const data = doc.data();
      const attendance = data.attendance;
      const timestamp = data.timestamp;

      if (attendance) {
        for (const [name, value] of Object.entries(attendance)) {
          if (value === false) {
            if (!acc[name]) {
              acc[name] = { count: 0, timestamps: [] };
            }
            acc[name].count += 1;
            acc[name].timestamps.push(timestamp);
          }
        }
      }
      return acc;
    }, {});

    const results = Object.entries(attendanceData).map(
      ([name, { count, timestamps }]) => ({
        name,
        absences: count,
        timestamps,
      })
    );

    return results;
  } catch (err) {
    console.error("Error getting documents: ", err);
    return [];
  }
}

export async function requestGetAttendanceList(email, date, setAttendance) {
  try {
    const parsedDate = parse(date, "dd-MM-yyyy", new Date());
    if (isNaN(parsedDate)) {
      throw new Error("Data inválida");
    }
    const timestamp = Timestamp.fromDate(parsedDate).seconds;
    const q = query(
      attendanceCollection,
      where("id", "==", email),
      where("timestamp", "==", timestamp)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("Nenhum documento encontrado.");
      setAttendance({});
      return [];
    }

    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setAttendance(results[0].attendance);
    return results;
  } catch (err) {
    console.error("Error getting documents: ", err);
    return [];
  }
}

export async function requestAddAttendanceList(email, date, attendance) {
  try {
    const parsedDate = parse(date, "dd-MM-yyyy", new Date());
    if (isNaN(parsedDate)) {
      throw new Error("Data inválida");
    }
    const timestamp = Timestamp.fromDate(parsedDate).seconds;
    const docRef = doc(attendanceCollection, date);
    await setDoc(docRef, {
      id: email,
      attendance,
      timestamp: timestamp,
    });

    return { status: "success", message: "Document created successfully" };
  } catch (err) {
    return {
      status: "error",
      message: "Add attendance list failed",
      error: err,
    };
  }
}

export async function requestGetClasses(email, setMyClasses) {
  try {
    const docRef = doc(classesCollection, email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const classesData = docSnap.data();
      setMyClasses(classesData.classes);
      return { status: "success", message: "Document get successfully" };
    } else {
      return {
        status: "error",
        message: "Classes not found",
      };
    }
  } catch (err) {
    return {
      status: "error",
      message: "Get classes failed",
      error: err,
    };
  }
}

export async function requestAddClasses(email, classes) {
  try {
    const docRef = doc(classesCollection, email);
    await setDoc(docRef, {
      id: email,
      classes: classes,
    });
    return {
      status: "success",
      message: "Document created successfully",
      id: docRef.id,
    };
  } catch (err) {
    return {
      status: "error",
      message: "Add classes failed",
      error: err,
    };
  }
}

export async function requestAddPayment(month, paymentData, email) {
  try {
    const docRef = doc(paymentCollection, month);
    await setDoc(docRef, {
      id: email,
      paymentData: paymentData,
      month: month,
    });
    return {
      status: "success",
      message: "Document created successfully",
      id: docRef.id,
    };
  } catch (err) {
    return {
      status: "error",
      message: "Add classes failed",
      error: err,
    };
  }
}

export async function requestGetPayment(month, setPaymentData) {
  try {
    const docRef = doc(paymentCollection, month);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setPaymentData({
        paymentData: docSnap.data().paymentData,
        month: docSnap.data().month,
      });
      return {
        status: "success",
        message: "Document retrieved successfully",
      };
    } else {
      setPaymentData([]);
      return {
        status: "error",
        message: "No such document!",
      };
    }
  } catch (err) {
    return {
      status: "error",
      message: "Failed to retrieve payment",
      error: err,
    };
  }
}

export async function requestAddStudent(name, phone) {
  try {
    const docRef = await addDoc(studentsCollection, {
      name: name,
      phone: phone,
    });

    return {
      status: "success",
      message: "Document created successfully",
      id: docRef.id,
    };
  } catch (err) {
    return {
      status: "error",
      message: "Add student failed",
      error: err,
    };
  }
}

export async function requestDeleteStudent(id) {
  try {
    const studentDocRef = doc(studentsCollection, id);
    await deleteDoc(studentDocRef);

    return {
      status: "success",
      message: "Estudante deletado com sucesso!",
    };
  } catch (error) {
    console.error("Erro ao deletar estudante:", error);
    return {
      status: "error",
      message: error.message,
    };
  }
}

export async function requestUpdateStudent(id, name, phone) {
  try {
    const studentDoc = doc(studentsCollection, id);
    await updateDoc(studentDoc, {
      name: name,
      phone: phone,
    });

    return { status: "success", message: "Student updated successfully" };
  } catch (err) {
    return {
      status: "error",
      message: "Failed to update student",
      error: err,
    };
  }
}

export async function requestGetStudents() {
  try {
    const querySnapshot = await getDocs(studentsCollection);
    const students = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return { status: "success", data: students };
  } catch (err) {
    return {
      status: "error",
      message: "Failed to retrieve students",
      error: err,
    };
  }
}

export async function requestGetSchedules(email, setSchedules) {
  try {
    const docRef = doc(schedulesCollection, email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const schedulesData = docSnap.data();
      setSchedules(schedulesData.schedules);
      return { status: "success", message: "Document get successfully" };
    } else {
      return {
        status: "error",
        message: "Schedules not found",
      };
    }
  } catch (err) {
    return {
      status: "error",
      message: "Get schedules failed",
      error: err,
    };
  }
}
