import React, { useState, useEffect } from "react";
import {
  requestAddStudent,
  requestGetStudents,
  requestDeleteStudent,
  requestUpdateStudent,
} from "../utils/resource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlus,
  faXmark,
  faHouse,
  faArrowLeft,
  faArrowRight,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import "../styles/Students.css";

const Students = () => {
  const navigate = useNavigate();

  const [newStudent, setNewStudent] = useState({ name: "", phone: "" });
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("name");

  const itemsPerPage = 5;

  const sortedStudents = (students) => {
    return students.sort((a, b) => {
      if (sortCriteria === "name") {
        return a.name.localeCompare(b.name);
      } else {
        return a.phone.localeCompare(b.phone);
      }
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("_id")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStudents = async () => {
      const result = await requestGetStudents();
      if (result.status === "success") {
        setStudents(result.data);
        setFilteredStudents(sortedStudents(result.data));
        setTotalPages(Math.ceil(result.data.length / itemsPerPage));
      }
    };
    fetchStudents();
  }, []);

  useEffect(() => {
    const results = sortedStudents(
      students.filter((student) =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredStudents(results);
    setTotalPages(Math.ceil(results.length / itemsPerPage));
    setCurrentPage(1);
  }, [searchTerm, students]);

  const handleUpdateStudent = async (e) => {
    e.preventDefault();

    if (!newStudent.name.trim() || !newStudent.phone.trim()) {
      alert("Por favor, preencha os campos nome e telefone.");
      return;
    }

    if (selectedStudent) {
      const result = await requestUpdateStudent(
        selectedStudent.id,
        newStudent.name,
        newStudent.phone
      );
      if (result.status === "success") {
        const updatedStudents = students.map((student) =>
          student.id === selectedStudent.id
            ? { ...student, name: newStudent.name, phone: newStudent.phone }
            : student
        );
        setStudents(updatedStudents);
        setFilteredStudents(updatedStudents);
        setSelectedStudent(null);
        setNewStudent({ name: "", phone: "" });
        setIsAdding(false);
      }
    } else {
      const result = await requestAddStudent(newStudent.name, newStudent.phone);
      if (result.status === "success") {
        const updatedStudents = [...students, { id: result.id, ...newStudent }];
        setStudents(updatedStudents);
        setFilteredStudents(updatedStudents);
        setNewStudent({ name: "", phone: "" });
        setIsAdding(false);
      }
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleDeleteStudent = async (studentId, studentName) => {
    const confirmDelete = window.confirm(
      `Tem certeza que deseja excluir o(a) aluno(a) ${studentName}?`
    );

    if (confirmDelete) {
      try {
        const response = await requestDeleteStudent(studentId);
        if (response.status === "success") {
          setStudents((prevStudents) =>
            prevStudents.filter((student) => student.id !== studentId)
          );
        } else {
          alert("Erro ao excluir aluno. Tente novamente.");
        }
      } catch (error) {
        console.error("Erro ao deletar aluno:", error);
        alert("Erro ao excluir aluno. Tente novamente.");
      }
    }
  };

  const handleEditStudent = (student) => {
    setNewStudent({ name: student.name, phone: student.phone }); // Preenche os campos de edição com os dados do aluno
    setSelectedStudent(student); // Define o aluno selecionado
    setIsAdding(true); // Inicia o modo de edição
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student); // Apenas seleciona o aluno para exibir detalhes
    setIsAdding(false); // Garante que não esteja no modo de adição/edição
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="students-container">
      <main className="students-main">
        <div className="button-bar">
          {isAdding ? (
            <>
              <FontAwesomeIcon
                icon={faSave}
                className="icon save-icon"
                onClick={handleUpdateStudent} // Salva as alterações
                title="Salvar"
              />
              <FontAwesomeIcon
                icon={faXmark}
                className="icon cancel-icon"
                onClick={() => {
                  setIsAdding(false); // Cancela o modo de edição
                  setNewStudent({ name: "", phone: "" }); // Limpa os campos
                  setSelectedStudent(null); // Limpa a seleção do aluno
                }}
                title="Cancelar"
              />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className="icon add-icon"
                onClick={() => setIsAdding(true)} // Habilita o modo de adição
                title="Adicionar Estudante"
              />
              <FontAwesomeIcon
                icon={faHouse}
                className="icon cancel-icon"
                onClick={handleCancel}
                title="Cancelar"
              />
            </>
          )}
        </div>

        {isAdding && (
          <div className="students-form">
            <div className="form-group">
              <label htmlFor="studentName">Nome</label>
              <input
                type="text"
                id="studentName"
                value={newStudent.name}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, name: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="studentPhone">Celular</label>
              <InputMask
                mask="(99) 99999-9999"
                id="studentPhone"
                value={newStudent.phone}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, phone: e.target.value })
                }
                required
              />
            </div>
          </div>
        )}

        <div className="students-search">
          <input
            type="text"
            placeholder="Buscar por nome ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="students-list">
          <p className="students-list-title">
            Lista de Alunos ({filteredStudents.length})
          </p>
          {paginatedStudents.length > 0 ? (
            <ul>
              {paginatedStudents.map((student) => (
                <li key={student.id}>
                  <p onClick={() => handleStudentClick(student)}>
                    {student.name}
                  </p>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="icon edit-icon"
                    onClick={() => handleEditStudent(student)} // Chama a edição ao clicar no ícone
                    title="Editar"
                  />
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="icon delete-icon"
                    onClick={() =>
                      handleDeleteStudent(student.id, student.name)
                    }
                    title="Excluir"
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p>Nenhum aluno encontrado.</p>
          )}

          <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={index + 1 === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>

        {selectedStudent &&
          !isAdding && ( // Exibe os detalhes apenas se não estiver editando
            <div className="student-details">
              <p>
                <strong>Nome:</strong> {selectedStudent.name}
              </p>
              <p>
                <strong>Celular:</strong> {selectedStudent.phone}
              </p>
            </div>
          )}
      </main>
    </div>
  );
};

export default Students;
