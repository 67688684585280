import React, { useState } from "react";
import "../styles/BioLinks.css";
import logo from "../imgs/logo-jessica-2.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faWhatsapp,
  faElementor,
} from "@fortawesome/free-brands-svg-icons";

const BioLinks = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="bio-links-container">
      <header className="header">
        <div className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faElementor} />
        </div>
        {menuOpen && (
          <nav className="menu">
            <a href="/admin" className="admin-button">
              Área Administrativa
            </a>
          </nav>
        )}
      </header>

      {/* Logo e conteúdo */}
      <img src={logo} alt="Logo" className="logo" />
      <p className="follow-me">Siga-nos no Instagram e agende sua aula!</p>

      {/* Links de redes sociais */}
      <div className="links">
        <a
          href="https://www.instagram.com/jessicanzanardi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </a>
        <a
          href="https://wa.me/5518997958480?text=Ol%C3%A1,%20tenho%20interesse%20em%20agendar%20uma%20aula%2E"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} /> Agendar Aulas
        </a>
      </div>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default BioLinks;
